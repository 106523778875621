<template lang="pug">
    div(class="bar-header blue-600")
        h2(class="title text-h6 px-5 py-2 yellow-300--text") {{title}}  
</template>

<script>
export default {
    name: "BarHeader",
    props: {
        title: {
            type: String,
            required: true
        },
    }
}
</script>