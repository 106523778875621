<template lang="pug">
	div
		bar-header( title="Coppel Analitica Omnicanal / Recomendador Universal" )
		v-container(fluid class="pa-0")
			v-row(justify="center")
				v-col(sm="4" class="px-5 pt-5")
					div(class="container-buttons")
						v-btn(
							v-for="item, index in items" 
							:key="item.id" 
							small
							block 
							class="justify-start blue-600 black yellow-300--text mb-2"
							:disabled="item.disabled"
							@click="goToRoute(item.routeName)") {{item.text}}
				v-col(sm="8" class="")
					

</template>

<script>
// Modelos dinámicos
import BarHeader from './components/BarHeader.vue'

export default {
	name: 'Segmentador',

	components: {
		BarHeader
	},
	computed: {
	},
	data() {
		return {
			items: [
				{
					id: 1,
					text: 'Generar Segmentos',
					routeName: 'ruform_segments',
					disabled: false
				},
				{
					id: 2,
					text: 'Generar Recomendaciones',
					routeName: 'ruform_segments',
					disabled: true
				},
				{
					id: 3,
					text: 'Generar Modelos',
					routeName: 'ruform_segments',
					disabled: true
				}
			],
		}
	},
	methods: {
		goToRoute(routeName) {
			this.$router.push({name: routeName});
		}
	},
}
</script>
<style>
	
</style>



